import React from 'react';

import { gql, useMutation, useQuery, useApolloClient } from '@apollo/client';
import { DoneAll, Refresh } from '@mui/icons-material';
import type { StackProps } from '@mui/material';
import { Box, Fab, IconButton, Stack, Typography, Zoom } from '@mui/material';
import { filter, find, get, includes, isEmpty, isNumber, map, reduce, size } from 'lodash';
import { CardLevel, LoadingSpinner } from '../../../components';
import { CreditNotEnoughPopup } from '../../../components/credit_not_enough_popup';
import { createScopedI18n, i18n } from '../../../i18n/i18n';
import { joinPairs } from '../../../utils/libs';
import Snackbar, { INSUFFICIENT_BALANCE } from '../../../utils/snackbar';
import StudentCandidateCard from './student_candidate_card';
import type { SkillType, StudentCandidateType } from './student_candidate_card';
import { getJobHiringsV2Gql } from './job_hire_list';

const jobDetailPageI18n = createScopedI18n('pages.jobs_id');
const creditErrorI18n = createScopedI18n('graphql.errors.types.credit');

const jobFields = gql`
  fragment JobFields on Job {
    id
    status
    jobType
    numberOfPosition
    numberOfHired
    jobApplicants {
      id
      status
    }
    skills {
      id
      name
    }
  }
`;

export const jobApplicantFields = gql`
  fragment JobApplicantFields on JobApplicant {
    id
    status
    restaurantChargePerHour
    job {
      ...JobFields
    }
    student {
      id
      avatar
      nickName
      headline
      rating
      isWorkedHere
      lastStudentCovid19Vaccine {
        id
        nth
        vaccinedAt
      }
      studentExperiences {
        id
        jobType
        minute
      }
      skills {
        id
        name
      }
      studentPreExperiences
      studentCertificates {
        id
        certificateType
      }
      latestCommends
    }
  }
  ${jobFields}
`;

export const getJobApplicantsV2Gql = gql`
  query GetJobApplicantsV2($jobId: ID) {
    jobApplicantsV2(jobId: $jobId) {
      ...JobApplicantFields
    }
  }
  ${jobApplicantFields}
`;

const acceptAppliedAtudentMutation = gql(`
  mutation acceptAppliedStudent($jobApplicantId: ID!) {
    acceptAppliedStudent(jobApplicantId: $jobApplicantId) {
      success
      errors
    }
  }
`);

type JobApplicantType = {
  id: string;
  restaurantChargePerHour?: number;
  student: StudentCandidateType;
  status: string;
  job: {
    jobType: string;
    numberOfPosition: number;
    numberOfHired: number;
    skills?: SkillType[] | null;
  };
};

export type JobCandidateListProps = {
  jobId?: string;
  onCardClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined, jobApplicantId: string) => void;
  onSelectedJobApplicantIdsChange?: (selectedJobApplicantIds: string[]) => void;
  outerStackProps?: StackProps;
};

const JobCandidateList = ({ jobId, onSelectedJobApplicantIdsChange, outerStackProps }: JobCandidateListProps) => {
  const client = useApolloClient();
  const [isNotEnoughCredit, setIsNotEnoughCredit] = React.useState(false);
  const [selectedJobApplicantIds, setSelectedJobApplicantIds] = React.useState<string[]>([]);
  const [errorMessageMap, setErrorMessageMap] = React.useState<{ [jobApplicantId: string]: string | null | undefined }>(
    {},
  );
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const { data, loading, refetch } = useQuery(getJobApplicantsV2Gql, {
    variables: { jobId },
    skip: !jobId,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    // Auto-Update job data in DateJobCard based on jobApplicantsV2 result
    onCompleted: (result) => {
      const jobApplicantsV2 = result?.jobApplicantsV2 ?? [];

      client.cache.modify({
        id: client.cache.identify({ __typename: 'Job', id: jobId }),
        fields: {
          jobApplicants: () => jobApplicantsV2,
        },
      });
    },
  });

  const [acceptAppliedStudentMutation] = useMutation(acceptAppliedAtudentMutation);

  const jobApplicants: JobApplicantType[] = data?.jobApplicantsV2 ?? [];
  const jobSkills = get(jobApplicants, [0, 'job', 'skills'], []);
  const remainPosition =
    get(jobApplicants, [0, 'job', 'numberOfPosition'], 0) - get(jobApplicants, [0, 'job', 'numberOfHired'], 0);
  const isMaxSelected = size(selectedJobApplicantIds) >= remainPosition;

  const toggleSelectedHandlerHigherOrder = (id: string) => () => {
    let nextSelectedJobApplicantIds = selectedJobApplicantIds ?? [];
    if (includes(nextSelectedJobApplicantIds, id)) {
      nextSelectedJobApplicantIds = filter(nextSelectedJobApplicantIds, (jobApplicantId) => jobApplicantId !== id);
    } else if (size(nextSelectedJobApplicantIds) < remainPosition) {
      nextSelectedJobApplicantIds = [...nextSelectedJobApplicantIds, id];
    }
    setSelectedJobApplicantIds(nextSelectedJobApplicantIds);

    if (onSelectedJobApplicantIdsChange) {
      onSelectedJobApplicantIdsChange(nextSelectedJobApplicantIds);
    }
  };

  const onCardErrorChangeHandlerHigherOrder = React.useCallback(
    (jobApplicantId: string) => (errorMessage: string | null, errorsPairs?: [string, string][] | null) => {
      setErrorMessageMap((prev) => ({ ...prev, [jobApplicantId]: errorMessage }));

      if (!isNotEnoughCredit && joinPairs(errorsPairs).includes(creditErrorI18n('not_enough'))) {
        setIsNotEnoughCredit(true);
        Snackbar.enqueueError(INSUFFICIENT_BALANCE);
      }
    },
    [isNotEnoughCredit],
  );

  const acceptAppliedStudentsHandler = async (jobApplicantIds: string[] = []) => {
    if (isEmpty(jobApplicantIds)) return;

    if (size(jobApplicantIds) > remainPosition) {
      setErrorMessageMap((prevState) =>
        reduce(
          jobApplicantIds,
          (prev, id) => ({ ...prev, [id]: i18n.t('errors.messages.too_many_accepted_student_candidate') }),
          prevState,
        ),
      );
      return;
    }

    setIsSubmitting(true);

    // eslint-disable-next-line no-restricted-syntax
    for (const jobApplicantId of jobApplicantIds) {
      setErrorMessageMap((prev) => ({ ...prev, [jobApplicantId]: null }));

      try {
        // eslint-disable-next-line no-await-in-loop
        const { data } = await acceptAppliedStudentMutation({
          variables: { jobApplicantId },

          // Update cache after mutation
          // * 1. Update JobApplicant status
          // * 2. Move accepted JobApplicant from jobApplicants to jobHirings
          // * 3. Update DateJobCard job.jobApplicants and job.jobHirings
          update: (cache, { data: mutationResult }) => {
            if (!mutationResult?.acceptAppliedStudent.success) return;

            const cachedQuery = cache.readQuery({
              query: getJobApplicantsV2Gql,
              variables: { jobId },
            });
            const currentJobApplicantsV2: JobApplicantType[] = get(cachedQuery, 'jobApplicantsV2', []);

            const acceptedJobApplicant = find(
              currentJobApplicantsV2,
              (jobApplicant) => jobApplicant.id === jobApplicantId,
            );

            if (isEmpty(acceptedJobApplicant)) return;

            // 1. Update jobApplicant status to 'match'
            cache.modify({
              id: cache.identify(acceptedJobApplicant),
              fields: { status: () => 'match' },
            });

            // 2. Move accepted JobApplicant from jobApplicants to jobHirings
            // New optimistic JobHiring based on jobApplicant
            const optimisticJobHiring = {
              ...acceptedJobApplicant,
              __typename: 'JobHiring',
              id: `__JobApplicant:${jobApplicantId}`,
              status: 'match',
            };

            // -  2.1 Remove jobApplicant from jobApplicants list
            cache.updateQuery({ query: getJobApplicantsV2Gql, variables: { jobId } }, (prevData) => {
              const nextData = {
                ...prevData,
                jobApplicantsV2: filter(prevData?.jobApplicantsV2, (applicant) => applicant.id !== jobApplicantId),
              };
              return nextData;
            });

            // -  2.2 Add jobApplicant as jobHiring to jobHirings list
            cache.updateQuery({ query: getJobHiringsV2Gql, variables: { jobId } }, (prevData) => {
              const nextData = {
                ...prevData,
                jobHiringsV2: [...(prevData?.jobHiringsV2 ?? []), optimisticJobHiring],
              };
              return nextData;
            });

            // 3. Add jobApplicant as jobHiring to job and update job status
            cache.modify({
              id: cache.identify({ __typename: 'Job', id: jobId }),
              fields: {
                jobHirings(prevJobHirings = []) {
                  return [...prevJobHirings, optimisticJobHiring];
                },
                numberOfHired: (prevNumberOfHired: number) => prevNumberOfHired + 1,
                status: (prevStatus: string, { readField, DELETE }) => {
                  const prevNumberOfHired = readField('numberOfHired');
                  const prevNumberOfPosition = readField('numberOfPosition');

                  if (isNumber(prevNumberOfHired) && isNumber(prevNumberOfPosition)) {
                    return prevNumberOfHired + 1 >= prevNumberOfPosition ? 'full' : prevStatus;
                  }
                  return DELETE; // Cannot determine status, yeet the cache to force refetch
                },
              },
            });
          },
        });

        if (data?.acceptAppliedStudent.success) {
          setSelectedJobApplicantIds((prev) => filter(prev, (id) => id !== jobApplicantId));
        } else {
          const errors = data?.acceptAppliedStudent.errors;

          if (joinPairs(errors).includes(creditErrorI18n('not_enough'))) {
            setIsNotEnoughCredit(true);
            Snackbar.enqueueError(INSUFFICIENT_BALANCE);
          }

          setErrorMessageMap((prev) => ({ ...prev, [jobApplicantId]: joinPairs(errors) }));
        }
      } catch (error) {
        setErrorMessageMap((prev) => ({ ...prev, [jobApplicantId]: get(error, 'message') }));
      }
    }

    setIsSubmitting(false);
  };

  return (
    <>
      <Stack
        flex={1}
        {...outerStackProps}
        sx={[
          { overflowX: 'auto' },
          ...(Array.isArray(outerStackProps?.sx) ? outerStackProps?.sx ?? [] : [outerStackProps?.sx]),
        ]}
      >
        <Stack flexDirection="row" alignItems="center" paddingX={3} paddingY={2}>
          <Typography variant="h6">{jobDetailPageI18n('job_applicants')}</Typography>
          <div style={{ flexGrow: 1 }} />
          <IconButton onClick={() => refetch()}>
            <Refresh />
          </IconButton>
        </Stack>

        <Box flex={1} sx={{ overflowY: 'scroll' }}>
          {loading || isEmpty(jobApplicants) ? (
            <CardLevel variant="outlined" sx={{ margin: 0.5 }}>
              {loading ? (
                <LoadingSpinner size={24} BoxProps={{ padding: 3 }} />
              ) : (
                <Stack padding={3} alignItems="center">
                  <Typography>{jobDetailPageI18n('job_applicant_empty')}</Typography>
                </Stack>
              )}
            </CardLevel>
          ) : (
            map(jobApplicants, ({ id, student, restaurantChargePerHour, job: { jobType } }) => {
              const isSelected = includes(selectedJobApplicantIds, id);
              return (
                <StudentCandidateCard
                  key={id}
                  jobApplicantId={id}
                  student={student}
                  restaurantChargePerHour={restaurantChargePerHour}
                  jobType={jobType}
                  jobSkills={jobSkills}
                  selected={isSelected}
                  disabled={isMaxSelected && !isSelected}
                  disableDetailDialogAction={isMaxSelected && !isSelected}
                  onAvatarClick={toggleSelectedHandlerHigherOrder(id)}
                  errorMessage={errorMessageMap ? errorMessageMap[id] : undefined}
                  onErrorMessageChange={onCardErrorChangeHandlerHigherOrder(id)}
                  sx={{ flex: 1, margin: 0.5 }}
                />
              );
            })
          )}
        </Box>
      </Stack>

      <Zoom in={!isEmpty(selectedJobApplicantIds)}>
        <Fab
          variant="extended"
          color="primary"
          disabled={isSubmitting}
          onClick={() => acceptAppliedStudentsHandler(selectedJobApplicantIds)}
          sx={{ position: 'fixed', bottom: 24, right: 24 }}
        >
          <DoneAll sx={{ marginRight: 1 }} />
          {jobDetailPageI18n('accept_job_applicants_count', {
            count: size(selectedJobApplicantIds),
            max: remainPosition,
          })}
        </Fab>
      </Zoom>

      <CreditNotEnoughPopup open={isNotEnoughCredit} onClosed={(_) => setIsNotEnoughCredit(false)} />
    </>
  );
};

export default JobCandidateList;
