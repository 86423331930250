import React from 'react';

import { gql, useMutation, useQuery } from '@apollo/client';
import { Alert, Button, Collapse, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { get, isEmpty, noop } from 'lodash';
import type { DialogProps } from '@mui/material';
import { LoadingButton } from '../../../components';
import { createScopedI18n } from '../../../i18n/i18n';
import { joinPairs } from '../../../utils/libs';

const getJobCancelFeeGql = gql(`
  query getJobCancelFeeDetail($jobId: ID!) {
    job(id: $jobId) {
      id
      cancelFee
      scheduleDate
      restaurant {
        id
      }
    }
  }
`);

const cancelJobGql = gql(`
  mutation cancelJob($jobId: ID!) {
    cancelJob(jobId: $jobId) {
      success
      errors
    }
  }
`);

const jobDetailPageI18n = createScopedI18n('pages.jobs_id');

export type CancelJobDialogProps = {
  jobId?: string;
  onSuccess?: () => void;
} & DialogProps;

export const CancelJobDialog = ({ jobId, onClose, onSuccess, ...dialogProps }: CancelJobDialogProps) => {
  const { data: cancelJobData, loading: cancelJobDataLoading } = useQuery(getJobCancelFeeGql, {
    variables: { jobId },
    fetchPolicy: 'network-only',
    skip: !dialogProps.open || isEmpty(jobId),
  });

  const [cancelJobMutation, { loading: cancelJobMutationLoading }] = useMutation(cancelJobGql, {
    update: (cache, { data }) => {
      if (!data?.cancelJob?.success) return;

      cache.evict({ id: cache.identify({ __typename: 'Job', id: jobId }) });
      cache.gc();
    },

    optimisticResponse: {
      cancelJob: {
        __typename: 'CancelJobPayload',
        success: true,
        errors: null,
      },
    },
  });

  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const submitCancelJobHandler = async () => {
    if (isEmpty(jobId)) return;

    setErrorMessage(null);

    try {
      const { data } = await cancelJobMutation({ variables: { jobId } });

      if (data?.cancelJob?.success) {
        if (onClose) onClose({}, 'backdropClick');
        if (onSuccess) onSuccess();
      } else {
        const errors = data?.cancelJob?.errors;
        setErrorMessage(joinPairs(errors));
      }
    } catch (error) {
      setErrorMessage(get(error, 'message', null));
    }
  };

  return (
    <Dialog onClose={onClose} {...dialogProps}>
      <DialogTitle>{jobDetailPageI18n('cancel_job_title', { count: cancelJobData?.job?.cancelFee ?? 0 })}</DialogTitle>

      <Collapse in={!isEmpty(errorMessage)} sx={{ width: '100%', paddingX: 2 }}>
        <Alert severity="error">{errorMessage}</Alert>
      </Collapse>

      <DialogActions>
        <LoadingButton
          color="error"
          loading={cancelJobMutationLoading}
          disabled={cancelJobDataLoading || cancelJobMutationLoading}
          onClick={submitCancelJobHandler}
        >
          {jobDetailPageI18n('cancel_job_fee', { count: cancelJobData?.job?.cancelFee ?? 0 })}
        </LoadingButton>
        <Button variant="contained" disableElevation onClick={onClose ?? noop}>
          {jobDetailPageI18n('continue_job')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
