import React from 'react';

import { gql, useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert,
  Collapse,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { get, includes, isNil } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { useMatch, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import type { SubmitHandler } from 'react-hook-form';
import {
  BottomSafeArea,
  LoadingButton,
  LoadingSpinner,
  UploadImagePickerCard,
  useGoBack,
  useTrackPageView,
} from '../../components';
import { AppContext } from '../../contexts/app_context';
import { useLoadUserInfoContext } from '../../routes/use_load_user_info_context';
import { createScopedI18n, createI18nErrorMessages, i18n } from '../../i18n/i18n';

const profileEditPageI18n = createScopedI18n('pages.profile_edit');
const profileEditFieldI18n = createScopedI18n('graphql.fields.upsert_user_restaurant_contact');
const profileEditErrorI18n = createScopedI18n('graphql.errors.types.upsert_user_restaurant_contact.fields', {
  joinOutput: true,
});

const ProfileEditSchema = Yup.object({
  isCompany: Yup.boolean().required(profileEditErrorI18n('is_company.blank')),

  contactName: Yup.string()
    .required(profileEditErrorI18n('contact_name.blank'))
    .matches(/^[\d\w\s\u0E00-\u0E7F]+$/, profileEditErrorI18n('contact_name.invalid')), // Only allow Alphabet, Number, Whitespace, and Thai (https://unicode.org/charts/nameslist/n_0E00.html) chars
  contactEmail: Yup.string()
    .required(profileEditErrorI18n('contact_email.blank'))
    .email(profileEditErrorI18n('contact_email.invalid')),
  contactAddress: Yup.string().when('isCompany', ([isCompany], schema) =>
    isCompany ? schema : schema.required(profileEditErrorI18n('contact_address.invalid')),
  ),
  contactThaiId: Yup.string().when('isCompany', ([isCompany], schema) => {
    if (isCompany) {
      return schema;
    }

    return schema
      .required(profileEditErrorI18n('contact_thai_id.blank'))
      .length(13, ({ length }) => profileEditErrorI18n('contact_thai_id.wrong_length', { count: length }));
  }),
  contactThaiCard: Yup.string(),
  contactThaiCardToken: Yup.string().when(['isCompany', 'contactThaiCard'], ([isCompany, contactThaiCard], schema) => {
    if (isCompany) return schema;

    if (isNil(contactThaiCard)) {
      return schema.required(profileEditErrorI18n('contact_thai_card_token.blank'));
    }
    return schema;
  }),

  companyName: Yup.string().when('isCompany', ([isCompany], schema) =>
    isCompany ? schema.required(profileEditErrorI18n('company_name.blank')) : schema,
  ),
  companyTaxId: Yup.string().when('isCompany', ([isCompany], schema) =>
    isCompany ? schema.required(profileEditErrorI18n('company_tax_id.blank')) : schema,
  ),
  companyAddress: Yup.string().when('isCompany', ([isCompany], schema) =>
    isCompany ? schema.required(profileEditErrorI18n('company_address.blank')) : schema,
  ),
});

type ProfileEditFormData = Yup.InferType<typeof ProfileEditSchema>;

const getChainProfileGql = gql(`
  query getChainProfile {
    user {
      id
      userRestaurantContact {
        id
        isCompany
        companyName
        companyAddress
        companyTaxId
        contactName
        contactEmail
        contactAddress
        contactThaiId
        contactThaiCard
        isInfoCompleted
      }
    }
  }
`);

const upsertUserRestaurantContactGql = gql(`
  mutation upsertUserRestaurantContact(
    $isCompany: Boolean
    $companyName: String
    $companyAddress: String
    $companyTaxId: String
    $contactAddress: String
    $contactEmail: String
    $contactName: String
    $contactThaiCardToken: String
    $contactThaiId: String
  ) {
    upsertUserRestaurantContact(
      isCompany: $isCompany
      companyName: $companyName
      companyAddress: $companyAddress
      companyTaxId: $companyTaxId
      contactAddress: $contactAddress
      contactEmail: $contactEmail
      contactName: $contactName
      contactThaiCardToken: $contactThaiCardToken
      contactThaiId: $contactThaiId
    ) {
      success
      errors
      data {
        id
        isCompany
        companyName
        companyAddress
        companyTaxId
        contactName
        contactEmail
        contactAddress
        contactThaiId
        contactThaiCard
        isInfoCompleted
      }
    }
  }
`);

// eslint-disable-next-line import/prefer-default-export
export const ProfileEditPage = () => {
  const navigate = useNavigate();

  const isFirstProfileEdit = useMatch('/first_profile_edit');
  const goBack = useGoBack({ fallbackPath: isFirstProfileEdit ? '/' : '/profile' });

  useTrackPageView('ProfileEditPage');

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      isCompany: false,
    },
    resolver: yupResolver(ProfileEditSchema),
  });

  const { contextUser, setContextInfoCompleteStatus, contextRedirectFrom, setContextRedirectFrom } =
    React.useContext(AppContext);
  const { fetchUserInfo } = useLoadUserInfoContext({ skip: true });
  const canEdit = includes(contextUser?.permissions, 'edit_profile');

  const isCompany = watch('isCompany');
  const contactThaiCard = watch('contactThaiCard');

  const { data, loading } = useQuery(getChainProfileGql, { skip: !canEdit });
  React.useEffect(() => {
    if (!loading && data?.user.userRestaurantContact) {
      const { userRestaurantContact } = data.user;
      reset({
        isCompany: userRestaurantContact.isCompany ?? false,
        contactName: userRestaurantContact.contactName ?? undefined,
        contactEmail: userRestaurantContact.contactEmail ?? undefined,
        contactAddress: userRestaurantContact.contactAddress ?? undefined,
        contactThaiId: userRestaurantContact.contactThaiId ?? undefined,
        contactThaiCard: userRestaurantContact.contactThaiCard ?? undefined,
        companyName: userRestaurantContact.companyName ?? undefined,
        companyTaxId: userRestaurantContact.companyTaxId ?? undefined,
        companyAddress: userRestaurantContact.companyAddress ?? undefined,
      });
    }
  }, [loading, reset, data?.user.userRestaurantContact]);

  const [upsertUserRestaurantContactMutation] = useMutation(upsertUserRestaurantContactGql);
  const [errorMessage, setErrorMessage] = React.useState<string | null>();

  const submitHandler: SubmitHandler<ProfileEditFormData> = async (values) => {
    setErrorMessage(null);

    try {
      const { data: resultData } = await upsertUserRestaurantContactMutation({
        variables: {
          isCompany: values.isCompany,
          contactName: values.contactName,
          contactEmail: values.contactEmail,
          contactAddress: values.contactAddress,
          contactThaiId: values.contactThaiId,
          contactThaiCardToken: values.contactThaiCardToken,
          companyName: values.companyName,
          companyTaxId: values.companyTaxId,
          companyAddress: values.companyAddress,
        },
      });

      if (resultData?.upsertUserRestaurantContact?.success) {
        fetchUserInfo(true);
        setContextInfoCompleteStatus({
          isInfoComplete: resultData?.upsertUserRestaurantContact?.data?.isInfoCompleted ?? false,
        });

        if (contextRedirectFrom) {
          navigate(contextRedirectFrom, { replace: true });
          setContextRedirectFrom(null);
        } else {
          goBack();
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (resultData?.upsertUserRestaurantContact?.data?.isInfoCompleted === false) {
          setErrorMessage(profileEditPageI18n('profile_incomplete', { joinOutput: true }));
        } else {
          const nextErrorMessage = createI18nErrorMessages(resultData);
          setErrorMessage(nextErrorMessage);
        }
      }
    } catch (error) {
      setErrorMessage(get(error, 'message'));
    }
  };

  if (!canEdit) {
    return (
      <Container
        maxWidth={'sm'}
        sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Alert severity="warning">{profileEditPageI18n('no_permission')}</Alert>
      </Container>
    );
  }

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Container maxWidth="sm" sx={{ paddingY: 2 }}>
      <form onSubmit={handleSubmit(submitHandler)}>
        <Grid container gap={2}>
          <Controller
            key="contactName"
            name="contactName"
            control={control}
            render={({ field: { value, ...field }, fieldState: { invalid, error } }) => (
              <FormControl id={field.name} fullWidth error={invalid}>
                <Grid container item alignItems={'center'} gap={1}>
                  <Grid item xs={3}>
                    <FormLabel id={field.name}>{profileEditFieldI18n('contact_name')} *</FormLabel>
                  </Grid>
                  <Grid item xs>
                    <TextField
                      id={field.name}
                      type="text"
                      fullWidth
                      value={value ?? ''}
                      error={invalid}
                      helperText={error?.message}
                      {...field}
                    />
                  </Grid>
                </Grid>
              </FormControl>
            )}
          />
          <Controller
            key="contactEmail"
            name="contactEmail"
            control={control}
            render={({ field: { value, ...field }, fieldState: { invalid, error } }) => (
              <FormControl id={field.name} fullWidth error={invalid}>
                <Grid container item alignItems={'center'} gap={1}>
                  <Grid item xs={3}>
                    <FormLabel id={field.name}>{profileEditFieldI18n('contact_email')} *</FormLabel>
                  </Grid>
                  <Grid item xs>
                    <TextField
                      id={field.name}
                      type="email"
                      fullWidth
                      value={value ?? ''}
                      error={invalid}
                      helperText={error?.message}
                      {...field}
                    />
                  </Grid>
                </Grid>
              </FormControl>
            )}
          />

          <Controller
            key="isCompany"
            name="isCompany"
            control={control}
            render={({ field: { value: isCompanyValue, onChange, ...field }, fieldState: { invalid, error } }) => (
              <FormControl id={field.name} fullWidth error={invalid}>
                <Grid container item alignItems={'center'} columnGap={1}>
                  <Grid item xs={3}>
                    <FormLabel id={field.name}>{profileEditFieldI18n('is_company')} *</FormLabel>
                  </Grid>
                  <Grid item xs>
                    <RadioGroup
                      row
                      sx={{ gapX: 2 }}
                      value={isCompanyValue ? 'company' : 'individual'}
                      onChange={(_e, value) => onChange(value === 'company')}
                      {...field}
                    >
                      <FormControlLabel
                        value={'company'}
                        control={<Radio />}
                        label={profileEditFieldI18n('company_types.company')}
                      />
                      <FormControlLabel
                        value={'individual'}
                        control={<Radio />}
                        label={profileEditFieldI18n('company_types.individual')}
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid container item>
                    <FormHelperText>{error?.message}</FormHelperText>
                  </Grid>
                </Grid>
              </FormControl>
            )}
          />
        </Grid>

        <Collapse in={isCompany}>
          <Grid container gap={2} marginTop={2}>
            <Controller
              key="companyName"
              name="companyName"
              control={control}
              render={({ field: { value, ...field }, fieldState: { invalid, error } }) => (
                <FormControl id={field.name} fullWidth error={invalid}>
                  <Grid container item alignItems={'center'} gap={1}>
                    <Grid item xs={3}>
                      <FormLabel id={field.name}>{profileEditFieldI18n('company_name')} *</FormLabel>
                    </Grid>
                    <Grid item xs>
                      <TextField
                        id={field.name}
                        type="text"
                        fullWidth
                        value={value ?? ''}
                        error={invalid}
                        helperText={error?.message}
                        {...field}
                      />
                    </Grid>
                  </Grid>
                </FormControl>
              )}
            />
            <Controller
              key="companyTaxId"
              name="companyTaxId"
              control={control}
              render={({ field: { value, ...field }, fieldState: { invalid, error } }) => (
                <FormControl id={field.name} fullWidth error={invalid}>
                  <Grid container item alignItems={'center'} gap={1}>
                    <Grid item xs={3}>
                      <FormLabel id={field.name}>{profileEditFieldI18n('company_tax_id')} *</FormLabel>
                    </Grid>
                    <Grid item xs>
                      <TextField
                        id={field.name}
                        type="text"
                        fullWidth
                        value={value ?? ''}
                        error={invalid}
                        helperText={error?.message}
                        {...field}
                      />
                    </Grid>
                  </Grid>
                </FormControl>
              )}
            />
            <Controller
              key="companyAddress"
              name="companyAddress"
              control={control}
              render={({ field: { value, ...field }, fieldState: { invalid, error } }) => (
                <FormControl id={field.name} fullWidth error={invalid}>
                  <Grid container item alignItems={'center'} gap={1}>
                    <FormLabel id={field.name}>{profileEditFieldI18n('company_address')} *</FormLabel>
                    <TextField
                      id={field.name}
                      type="text"
                      fullWidth
                      multiline
                      minRows={3}
                      maxRows={3}
                      value={value ?? ''}
                      error={invalid}
                      helperText={error?.message}
                      {...field}
                    />
                  </Grid>
                </FormControl>
              )}
            />
          </Grid>
        </Collapse>

        <Collapse in={!isCompany}>
          <Grid container gap={2} marginTop={2}>
            <Controller
              key="contactThaiId"
              name="contactThaiId"
              control={control}
              render={({ field: { value, ...field }, fieldState: { invalid, error } }) => (
                <FormControl id={field.name} fullWidth error={invalid}>
                  <Grid container item alignItems={'center'} gap={1}>
                    <Grid item xs={3}>
                      <FormLabel id={field.name}>{profileEditFieldI18n('contact_thai_id')} *</FormLabel>
                    </Grid>
                    <Grid item xs>
                      <TextField
                        id={field.name}
                        type="text"
                        fullWidth
                        value={value ?? ''}
                        error={invalid}
                        helperText={error?.message}
                        {...field}
                      />
                    </Grid>
                  </Grid>
                </FormControl>
              )}
            />
            <Controller
              key="contactAddress"
              name="contactAddress"
              control={control}
              render={({ field: { value, ...field }, fieldState: { invalid, error } }) => (
                <FormControl id={field.name} fullWidth error={invalid}>
                  <Grid container item alignItems={'center'} gap={1}>
                    <FormLabel id={field.name}>{profileEditFieldI18n('contact_address')} *</FormLabel>
                    <TextField
                      id={field.name}
                      type="text"
                      fullWidth
                      multiline
                      minRows={3}
                      maxRows={3}
                      value={value ?? ''}
                      error={invalid}
                      helperText={error?.message}
                      {...field}
                    />
                  </Grid>
                </FormControl>
              )}
            />
            <Controller
              key="contactThaiCardToken"
              name="contactThaiCardToken"
              control={control}
              render={({ field: { onChange, ...field }, fieldState: { invalid, error } }) => (
                <FormControl id={field.name} fullWidth error={invalid}>
                  <Grid container item alignItems={'center'} gap={1}>
                    <FormLabel id={field.name}>{profileEditFieldI18n('contact_thai_card_token')} *</FormLabel>
                    <UploadImagePickerCard
                      id={field.name}
                      initialSrc={contactThaiCard}
                      onUploadDone={(token) => onChange(token)}
                    />
                    <FormHelperText>{error?.message}</FormHelperText>
                  </Grid>
                </FormControl>
              )}
            />
          </Grid>
        </Collapse>

        <Grid container gap={2} marginTop={2}>
          <Collapse in={!!errorMessage}>
            <Alert severity="error">{errorMessage}</Alert>
          </Collapse>

          <LoadingButton type="submit" disabled={isSubmitting} loading={isSubmitting} fullWidth variant="contained">
            {i18n.t('general.register')}
          </LoadingButton>
        </Grid>
      </form>

      <BottomSafeArea />
    </Container>
  );
};
