import React from 'react';

import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { debounce, find, isEmpty, isNil, map, round } from 'lodash';
import { useMatch } from 'react-router-dom';
import { ContainerFlexHeight, useTrackPageView } from '../../../components';
import { AppContext } from '../../../contexts/app_context';
import RestaurantDateJobsList from './restaurant_date_jobs_list';

import type { RestaurantType } from '../../../contexts/app_context';

const LANE_WIDTH = 330; // px
const LANE_MARGIN = 4; // px

const JobsPage = () => {
  const theme = useTheme();
  const isBiggerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  const jobPageMatch = useMatch('/jobs');
  const jobIdPathMatch = useMatch('/jobs/:id');
  const isJobsIdOpen = !!jobIdPathMatch?.params.id && jobIdPathMatch.params.id !== 'new';

  useTrackPageView('JobListPage', undefined, { trackUrlChange: true, trackUrlPattern: '/jobs' });

  const containerRef = React.useRef<HTMLDivElement>(null);
  const restaurantsLaneRef = React.useRef<{ [restaurantId: RestaurantType['id']]: HTMLDivElement | null }>({});
  const laneIndexToRestaurantIdRef = React.useRef<{ [index: number]: RestaurantType['id'] }>({});
  const skipSetCurrentRestaurantRef = React.useRef(false);
  const skipAutoScrollRef = React.useRef(false);

  const { contextCurrentRestaurant, contextRestaurants, setContextCurrentRestaurant } = React.useContext(AppContext);

  const updateCurrentRestaurant = React.useCallback(
    (restaurant: RestaurantType | null) => {
      skipAutoScrollRef.current = true;
      setContextCurrentRestaurant(restaurant);
      setTimeout(() => {
        skipAutoScrollRef.current = false;
      }, 10);
    },
    [setContextCurrentRestaurant],
  );

  const scrollToRestaurant = React.useCallback((restaurant: RestaurantType, options = { animate: true }) => {
    const laneRef = restaurantsLaneRef.current[restaurant.id];
    if (isNil(laneRef)) return;

    skipSetCurrentRestaurantRef.current = true;
    if (options.animate) {
      laneRef.scrollIntoView({ behavior: 'smooth', inline: 'start' });
      laneRef.animate({ opacity: [1, 0.25, 1, 0.25, 1] }, 1000);
    } else {
      laneRef.scrollIntoView({ behavior: 'instant', inline: 'start' });
    }
  }, []);

  const getCurrentScrollLane = React.useCallback((scrollLeft: number) => {
    const laneWidth = LANE_WIDTH + LANE_MARGIN + LANE_MARGIN; // hard-code lane width + marginLeft + marginRight
    const laneIndex = round(scrollLeft / laneWidth);
    const restaurantId = laneIndexToRestaurantIdRef.current[laneIndex];

    return { restaurantId, laneIndex };
  }, []);

  const onScrollHandler = React.useCallback(
    debounce(() => {
      if (skipSetCurrentRestaurantRef.current) {
        skipSetCurrentRestaurantRef.current = false;
        return;
      }

      const scrollLeft = containerRef.current?.scrollLeft;
      if (isNil(scrollLeft)) return;

      const { restaurantId } = getCurrentScrollLane(scrollLeft);
      const restaurant = find(contextRestaurants, ({ id }) => id === restaurantId);
      if (restaurant) {
        updateCurrentRestaurant(restaurant);
      }
    }, 100),
    [contextRestaurants, updateCurrentRestaurant],
  );

  React.useEffect(() => {
    if (contextCurrentRestaurant?.id && !skipAutoScrollRef.current) {
      scrollToRestaurant(contextCurrentRestaurant);
    }
  }, [contextCurrentRestaurant?.id]);

  React.useLayoutEffect(() => {
    const scrollLeft = containerRef.current?.scrollLeft;
    if (isNil(scrollLeft)) return;

    const { restaurantId } = getCurrentScrollLane(scrollLeft);
    if (contextCurrentRestaurant?.id && restaurantId !== contextCurrentRestaurant.id) {
      scrollToRestaurant(contextCurrentRestaurant, { animate: false });
    }
  }, []);

  React.useEffect(() => {
    if (
      jobPageMatch?.pathname &&
      isEmpty(contextCurrentRestaurant) &&
      !isNil(contextRestaurants) &&
      !isEmpty(contextRestaurants)
    ) {
      updateCurrentRestaurant(contextRestaurants[0]);
    }
  }, [contextCurrentRestaurant, contextRestaurants, jobPageMatch?.pathname, updateCurrentRestaurant]);

  return (
    <ContainerFlexHeight maxWidth={false} disableGutters sx={{ display: 'flex', flexDirection: 'row' }}>
      <Stack
        ref={containerRef}
        flex={1}
        flexDirection="row"
        sx={{
          overflowX: 'scroll',
          scrollSnapType: { xs: 'x mandatory', sm: 'inherit' },
          scrollPadding: '16px',
          paddingLeft: { xs: '48px', sm: '0' },
          paddingRight: { xs: '48px', sm: '0' },
        }}
        onScroll={onScrollHandler}
      >
        {map(contextRestaurants, (restaurant, index) => (
          <RestaurantDateJobsList
            key={restaurant.id}
            ref={(ref) => {
              restaurantsLaneRef.current[restaurant.id] = ref;
              laneIndexToRestaurantIdRef.current[index] = restaurant.id;
            }}
            restaurant={restaurant}
            outerStackProps={{
              width: LANE_WIDTH,
              margin: `${LANE_MARGIN}px`,
              sx: { scrollSnapAlign: 'center', scrollSnapStop: 'always' },
            }}
          />
        ))}
      </Stack>

      {/* Empty elem to push lane out of /jobs/:id Dialog when it opened */}
      <Stack width={isJobsIdOpen && isBiggerThanMd ? theme.breakpoints.values.md : 0} flexShrink={0} />
    </ContainerFlexHeight>
  );
};

export default JobsPage;
