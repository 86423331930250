import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Box, Button, ButtonGroup, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { ArrowDropDown, Delete, BorderColor, Close } from '@mui/icons-material';
import { isEmpty } from 'lodash';
import { createScopedI18n } from '../../../i18n/i18n';
import { CloseJobDialog } from './close_job_dialog';
import { CancelJobDialog } from './cancel_job_dialog';
import { AdjustWageDialog, JoinAIButton } from '../../../components';

const jobStatusGql = gql(`
  query getJobStatus($jobId: ID!) {
    job(id: $jobId) {
      id
      status
      jobType
    }
  }
`);

const jobDetailPageI18n = createScopedI18n('pages.jobs_id');

export type CloseOrCancelJobButtonType = {
  jobId?: string;
  onSuccess?: () => void;
};

export const CloseOrCancelJobButton = ({ jobId, onSuccess }: CloseOrCancelJobButtonType) => {
  const { data: jobStatusData, loading: jobStatusLoading } = useQuery(jobStatusGql, {
    variables: { jobId },
    skip: isEmpty(jobId),
  });

  const [menuAnchor, setMenuAnchor] = React.useState<HTMLElement | null>(null);
  const [showCloseJobDialog, setShowCloseJobDialog] = React.useState(false);
  const [showCancelJobDialog, setShowCancelJobDialog] = React.useState(false);
  const [showIncreaseWageDialog, setShowIncreaseWageDialog] = React.useState(false);

  const menuOpenHandler = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchor(event.currentTarget);
  };
  const menuCloseHandler = () => setMenuAnchor(null);

  const openCloseJobDialogHandler = () => setShowCloseJobDialog(true);
  const closeCloseJobDialogHandler = () => setShowCloseJobDialog(false);

  const openCancelJobDialogHandler = () => {
    menuCloseHandler();
    setShowCancelJobDialog(true);
  };
  const closeCancelJobDialogHandler = () => setShowCancelJobDialog(false);

  const openIncreaseWageDialogHandler = () => {
    menuCloseHandler();
    setShowIncreaseWageDialog(true);
  };
  const closeIncreaseWageDialogHandler = () => setShowIncreaseWageDialog(false);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'stretch', sm: 'center' },
          gap: 2,
          mt: 2,
        }}
      >
        <JoinAIButton
          variant="outlined"
          color="primary"
          placement="job_detail"
          jobType={jobStatusData?.job?.jobType}
          sx={{
            width: { xs: '100%', sm: 'auto' },
          }}
        />
        <ButtonGroup
          variant="outlined"
          color="error"
          disableElevation
          sx={{
            'width': { xs: '100%', sm: 'auto' },
            '& .MuiButton-root': {
              flex: { xs: 1, sm: 'initial' },
            },
          }}
        >
          <Button onClick={openIncreaseWageDialogHandler}>{jobDetailPageI18n('adjust_wage')}</Button>
          <Button
            size="small"
            onClick={menuOpenHandler}
            sx={{
              minWidth: '40px',
            }}
          >
            <ArrowDropDown />
          </Button>
        </ButtonGroup>
      </Box>

      <Menu
        open={!isEmpty(menuAnchor)}
        anchorEl={menuAnchor}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={menuCloseHandler}
      >
        <MenuItem
          disabled={jobStatusLoading || jobStatusData?.job?.status === 'cancel'}
          onClick={openIncreaseWageDialogHandler}
        >
          <ListItemIcon>
            <BorderColor fontSize="small" />
          </ListItemIcon>
          {jobDetailPageI18n('adjust_wage')}
        </MenuItem>
        <MenuItem
          disabled={jobStatusLoading || jobStatusData?.job?.status === 'cancel'}
          onClick={openCloseJobDialogHandler}
        >
          <ListItemIcon>
            <Close fontSize="small" />
          </ListItemIcon>

          {jobDetailPageI18n('close_job')}
        </MenuItem>
        <MenuItem
          disabled={jobStatusLoading || jobStatusData?.job?.status === 'cancel'}
          onClick={openCancelJobDialogHandler}
          sx={{ color: 'error.main' }}
        >
          <ListItemIcon>
            <Delete fontSize="small" color="error" />
          </ListItemIcon>
          {jobDetailPageI18n('cancel_job')}
        </MenuItem>
      </Menu>

      <CloseJobDialog
        open={showCloseJobDialog}
        jobId={jobId}
        onClose={closeCloseJobDialogHandler}
        onSuccess={onSuccess}
      />

      <CancelJobDialog
        open={showCancelJobDialog}
        jobId={jobId}
        onClose={closeCancelJobDialogHandler}
        onSuccess={onSuccess}
      />

      <AdjustWageDialog
        open={showIncreaseWageDialog}
        jobId={jobId || ''}
        onClose={closeIncreaseWageDialogHandler}
        onSuccess={onSuccess}
      />
    </>
  );
};
