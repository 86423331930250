import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { ExpandMore, Refresh } from '@mui/icons-material';
import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  Stack,
  Typography,
  createSvgIcon,
} from '@mui/material';
import dayjs from 'dayjs';
import { filter, isEmpty, isNil, map, reduce, size } from 'lodash';
import type { CardProps } from '@mui/material';
import { CardLevel, LoadingSpinner } from '../../../components';
import { formatDate } from '../../../utils/libs';
import { ExportJobHiringsExcelDialog } from './export_job_hirings_excel_dialog';
import { JobCardItem } from './job_card_item';
import { i18n } from '../../../i18n/i18n';

const JOB_APPLICANT_FIELDS = gql`
  fragment JobApplicantFields on JobApplicant {
    id
    status
  }
`;

const JOB_HIRING_FIELDS = gql`
  fragment JobHiringFields on JobHiring {
    id
    status
  }
`;

const JOB_FIELDS = gql`
  fragment JobFields on Job {
    id
    jobType
    status
    numberOfPosition
    scheduleDate
    jobStartAt
    jobEndAt
    expiredAt
    isImmediateAccept
    jobApplicants {
      ...JobApplicantFields
    }
    jobHirings {
      ...JobHiringFields
    }
  }
  ${JOB_APPLICANT_FIELDS}
  ${JOB_HIRING_FIELDS}
`;

const getDateJobsGql = gql`
  query getDateJobs($restaurantId: ID, $date: Date!) {
    jobs(restaurantId: $restaurantId, date: $date) {
      ...JobFields
    }
  }
  ${JOB_FIELDS}
`;

export type DateJobsCardProps = {
  date: Date | string;
  restaurantId: string;
} & CardProps;

const ExcelIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M21.17 3.25Q21.5 3.25 21.76 3.5 22 3.74 22 4.08V19.92Q22 20.26 21.76 20.5 21.5 20.75 21.17 20.75H7.83Q7.5 20.75 7.24 20.5 7 20.26 7 19.92V17H2.83Q2.5 17 2.24 16.76 2 16.5 2 16.17V7.83Q2 7.5 2.24 7.24 2.5 7 2.83 7H7V4.08Q7 3.74 7.24 3.5 7.5 3.25 7.83 3.25M7 13.06L8.18 15.28H9.97L8 12.06L9.93 8.89H8.22L7.13 10.9L7.09 10.96L7.06 11.03Q6.8 10.5 6.5 9.96 6.25 9.43 5.97 8.89H4.16L6.05 12.08L4 15.28H5.78M13.88 19.5V17H8.25V19.5M13.88 15.75V12.63H12V15.75M13.88 11.38V8.25H12V11.38M13.88 7V4.5H8.25V7M20.75 19.5V17H15.13V19.5M20.75 15.75V12.63H15.13V15.75M20.75 11.38V8.25H15.13V11.38M20.75 7V4.5H15.13V7Z" />
  </svg>,
  'Excel',
);

const DateJobsCard = React.forwardRef<HTMLDivElement, DateJobsCardProps>(
  ({ date, restaurantId, ...CardProps }, ref) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DD');
    const queryVariables = {
      date: formattedDate,
      restaurantId,
    };

    const { data, loading, networkStatus, refetch } = useQuery(getDateJobsGql, {
      variables: queryVariables,
      skip: !restaurantId,
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    });

    // data is return immediately if in cache, loading indicate is fetching from network or not
    const isInitialLoading = loading && isNil(data);
    // Check is refetching from networkStatus === 4
    // 1 is first fetch on mount, 4 is refetching called, 7 is idle
    const isRefetching = networkStatus === 4;
    // Show loading spinner if data is not in cache (first load) or refetching
    const showLoading = isInitialLoading || isRefetching;

    const jobsPositionCountMap = React.useMemo(
      () =>
        reduce(
          data?.jobs,
          (prev, job) => {
            const hirings = filter(
              job.jobHirings,
              (jobHiring) => jobHiring.status === 'match' || jobHiring.status === 'done',
            );
            const applicants = filter(job.jobApplicants, (jobApplicant) => jobApplicant.status === 'open');

            return { ...prev, [job.id]: { applicantCount: size(applicants), hiringCount: size(hirings) } };
          },
          {} as { [key: string]: { applicantCount: number; hiringCount: number } },
        ),
      [data?.jobs],
    );

    const [expand, setExpand] = React.useState(true);
    const [openExportExcelDialog, setOpenExportExcelDialog] = React.useState(false);

    const openExportExcelHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setOpenExportExcelDialog(true);
    };

    const refreshHandler = async (event?: React.MouseEvent<HTMLButtonElement>) => {
      event?.stopPropagation();
      await refetch();
    };

    return (
      <Card ref={ref} {...CardProps}>
        <CardActionArea component="span" onClick={() => setExpand(!expand)}>
          <CardHeader
            avatar={
              <ExpandMore sx={{ transform: expand ? 'rotate(180deg)' : 'rotate(360deg)', transition: '0.2s ease' }} />
            }
            title={formatDate(date, 'ddd DD MMM')}
            titleTypographyProps={{ variant: 'body1' }}
            action={
              <Stack flexDirection="row" gap={1}>
                <IconButton size="large" onClick={openExportExcelHandler}>
                  <ExcelIcon />
                </IconButton>
                <IconButton size="large" onClick={refreshHandler}>
                  <Refresh />
                </IconButton>
              </Stack>
            }
          />
        </CardActionArea>

        <Collapse in={expand}>
          <CardContent sx={{ paddingTop: 1 }}>
            <Collapse in={showLoading} unmountOnExit>
              <LoadingSpinner size={24} />
            </Collapse>

            <Collapse in={!showLoading && isEmpty(data?.jobs)} unmountOnExit>
              <CardLevel variant="outlined" level={1}>
                <CardContent>
                  <Typography variant="subtitle2" textAlign="center" sx={{ opacity: 0.6 }}>
                    {i18n.t('pages.jobs.no_jobs')}
                  </Typography>
                </CardContent>
              </CardLevel>
            </Collapse>

            <Collapse in={!showLoading}>
              <Stack gap={1}>
                {map(data?.jobs, (job) => (
                  <JobCardItem
                    key={job.id}
                    job={job}
                    applicantCount={jobsPositionCountMap[job.id].applicantCount}
                    hiringCount={jobsPositionCountMap[job.id].hiringCount}
                  />
                ))}
              </Stack>
            </Collapse>
          </CardContent>
        </Collapse>

        <ExportJobHiringsExcelDialog
          open={openExportExcelDialog}
          date={date}
          restaurantId={restaurantId}
          onClose={() => setOpenExportExcelDialog(false)}
        />
      </Card>
    );
  },
);

export default DateJobsCard;
