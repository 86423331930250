import React from 'react';

import { gql, useQuery } from '@apollo/client';
import { Refresh } from '@mui/icons-material';
import { CardContent, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { isEmpty, isNil, map } from 'lodash';
import type { StackProps } from '@mui/material';
import { CardLevel, LoadingSpinner, RestaurantTitle } from '../../../components';
import { i18n } from '../../../i18n/i18n';
import DateJobsCard from './date_jobs_card';
import type { RestaurantType } from '../../../contexts/app_context';
import { AppContext } from '../../../contexts/app_context';

export type RestaurantDateJobsListProps = {
  restaurant: RestaurantType;
  outerStackProps?: StackProps;
};

const incomingJobsGql = gql(`
  query incomingJobs($restaurantId: ID) {
    incomingJobs(restaurantId: $restaurantId) {
      date
    }
  }
`);

export const RestaurantDateJobsList = React.forwardRef<HTMLDivElement, RestaurantDateJobsListProps>(
  ({ restaurant, outerStackProps }, ref) => {
    const theme = useTheme();
    const { updateContextRestaurant } = React.useContext(AppContext);

    const { data, loading, networkStatus, refetch } = useQuery(incomingJobsGql, {
      variables: { restaurantId: restaurant.id },
      skip: !restaurant.id,
      fetchPolicy: 'cache-and-network', // Return cached data immediately if exist while new data is fetching
      notifyOnNetworkStatusChange: true,
    });

    // data is return immediately if in cache, loading indicate is fetching from network or not
    const isInitialLoading = loading && isNil(data);
    // Check is refetching from networkStatus === 4
    // 1 is first fetch on mount, 4 is refetching called, 7 is idle
    const isRefetching = networkStatus === 4;
    // Show loading spinner if data is not in cache (first load) or refetching
    const showLoading = isInitialLoading || isRefetching;

    const refreshHandler = React.useCallback(async () => {
      await refetch();
    }, [refetch]);

    React.useEffect(() => {
      if (loading) {
        return;
      }

      updateContextRestaurant(restaurant.id, (prevRestaurant) => ({
        ...prevRestaurant,
        isHiring: !isEmpty(data?.incomingJobs),
      }));
    }, [loading, data?.incomingJobs]);

    return (
      <Stack
        ref={ref}
        flexShrink={0}
        border={1}
        borderRadius={1}
        borderColor={theme.palette.divider}
        {...outerStackProps}
      >
        <Stack paddingX={1} paddingTop={0.75}>
          <Stack flexDirection="row" alignItems="center" justifyContent="flex-end" gap={1}>
            <IconButton size="small" color="info" onClick={refreshHandler}>
              <Refresh />
            </IconButton>
          </Stack>

          <Stack flexDirection="row" alignItems="center" height={64}>
            <RestaurantTitle
              imageUri={restaurant.frontPhotoUri}
              name={restaurant.name}
              branchName={restaurant.branchName}
            />
          </Stack>
        </Stack>

        {showLoading ? (
          <LoadingSpinner />
        ) : (
          <Stack flex={1} sx={{ overflowY: 'scroll', scrollPadding: '16px' }}>
            <Stack gap={1} padding={0.75}>
              {isEmpty(data?.incomingJobs) ? (
                <CardLevel variant="outlined" level={1}>
                  <CardContent>
                    <Typography variant="subtitle2" textAlign="center" sx={{ opacity: 0.6 }}>
                      {i18n.t('pages.jobs.no_jobs')}
                    </Typography>
                  </CardContent>
                </CardLevel>
              ) : (
                map(data?.incomingJobs, ({ date }) => (
                  <DateJobsCard key={date} date={date} restaurantId={restaurant.id} />
                ))
              )}
            </Stack>
          </Stack>
        )}
      </Stack>
    );
  },
);

export default RestaurantDateJobsList;
