/* eslint-disable no-nested-ternary */
import React from 'react';

import { NavigateNext } from '@mui/icons-material';
import { CardActionArea, CardContent, ListItemText, Stack, Typography, darken, lighten, useTheme } from '@mui/material';
import { Link, useMatch } from 'react-router-dom';
import { CardLevel, LinearProgressWithLabel } from '../../../components';
import { formatTime, labelFieldEnum } from '../../../utils/libs';
import JobStatusChips from './job_status_chips';

export type JobCardItemProps = {
  job: {
    id: string;
    status: string;
    jobType: string;
    jobStartAt: string;
    jobEndAt: string;
    expiredAt: string;
    isImmediateAccept: boolean;
    numberOfPosition: number;
  };
  applicantCount?: number;
  hiringCount?: number;
};

export const JobCardItem = ({ job, applicantCount = 0, hiringCount = 0 }: JobCardItemProps) => {
  const theme = useTheme();

  const jobItemRef = React.useRef<HTMLDivElement>(null);
  const jobIdPathMatch = useMatch('/jobs/:id');
  const isJobsIdOpen = React.useMemo(
    () => !!jobIdPathMatch?.params.id && jobIdPathMatch.params.id !== 'new',
    [jobIdPathMatch?.params.id],
  );
  const selected = React.useMemo(() => jobIdPathMatch?.params.id === job.id, [job.id, jobIdPathMatch?.params.id]);

  const isScrollingRef = React.useRef(false);
  const delayedScrollJobIntoView = React.useCallback(() => {
    if (isScrollingRef.current) return;

    isScrollingRef.current = true;
    setTimeout(() => {
      jobItemRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
      isScrollingRef.current = false;
    }, 300);
  }, []);

  const [visible, setVisible] = React.useState<boolean | null>(null);
  React.useEffect(() => {
    // Setup to check is elem visible
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (visible === null) {
          setVisible(entry.isIntersecting);
        }
      },
      { threshold: 0.75 },
    );
    if (jobItemRef.current) observer.observe(jobItemRef.current);

    return () => observer.disconnect();
  }, [visible]);

  React.useEffect(() => {
    if (selected && !visible && visible !== null) {
      delayedScrollJobIntoView();
    }
  }, [delayedScrollJobIntoView, selected, visible]);

  // Adapt from @mui/material Alert.js
  const openJobBackgroundColor = React.useMemo(() => {
    const getBackgroundColor = theme.palette.mode === 'light' ? lighten : darken;
    return getBackgroundColor(theme.palette.warning.light, 0.75);
  }, [theme.palette.mode, theme.palette.warning.light]);

  return (
    <CardLevel
      ref={jobItemRef}
      variant="outlined"
      level={selected ? 3 : 1}
      sx={[
        {
          outlineWidth: 0,
          outlineStyle: 'solid',
          outlineColor: theme.palette.warning.main,
          transition: 'outline 50ms ease',
        },
        selected && { outlineWidth: 3 },
        job.status === 'open' && { backgroundColor: openJobBackgroundColor },
      ]}
    >
      <CardActionArea component={Link} to={job.id} replace={isJobsIdOpen} onClick={delayedScrollJobIntoView}>
        <CardContent>
          <Stack flexDirection="row">
            <ListItemText
              primary={`${labelFieldEnum('job.job_types', job.jobType)} ${formatTime(job.jobStartAt)} - ${formatTime(job.jobEndAt)}`}
            />
            <NavigateNext sx={{ marginY: 0.5 }} />
          </Stack>

          <Stack flex={1}>
            <LinearProgressWithLabel
              value={(hiringCount / job.numberOfPosition) * 100}
              label={() => (
                <Typography>
                  {hiringCount} / {job.numberOfPosition}
                </Typography>
              )}
            />
          </Stack>

          <Stack gap={0.5} flexDirection="row" flexWrap="wrap">
            <JobStatusChips
              jobStatus={job.status}
              jobExpiredAt={job.expiredAt}
              isImmediateAccept={job.isImmediateAccept}
              applicantCount={applicantCount}
            />
          </Stack>
        </CardContent>
      </CardActionArea>
    </CardLevel>
  );
};
