import React, { ReactElement, ReactNode } from 'react';
import { isEmpty } from 'lodash';
import { AppContext } from '../../contexts/app_context';
import ProtectorContext, { useProtectorContext, ProtectorState } from './context';

type NonEmptyArray<T> = [T, ...T[]];

interface ProtectorProps {
  permissions: NonEmptyArray<string>;
  children: ReactNode;
}

interface ProtectorChildProps {
  children: ReactNode;
}

const Protector = ({ permissions, children }: ProtectorProps) => {
  const { contextUser, contextPermissions } = React.useContext(AppContext);

  const [state, setState] = React.useState<ProtectorState>(null);

  if (isEmpty(permissions)) {
    throw new Error("โปรดติดต่อ Admin เพื่อแก้ไข (The 'permissions' prop cannot be an empty array.)");
  }

  React.useEffect(() => {
    const restaurantPermissions = contextPermissions?.restaurant || {};
    const chainPermissions = contextPermissions?.chain || {};

    const restaurantScopeGranted = permissions.some(
      (perm) => restaurantPermissions[perm as keyof typeof restaurantPermissions],
    );
    const chainScopeGranted = permissions.some((perm) => chainPermissions[perm as keyof typeof chainPermissions]);

    setState(restaurantScopeGranted || chainScopeGranted ? 'valid' : 'invalid');
  }, [permissions, contextUser, contextPermissions]);

  return <ProtectorContext.Provider value={{ state, setState }}>{children}</ProtectorContext.Provider>;
};

const Allowed = ({ children }: ProtectorChildProps) => {
  const { state } = useProtectorContext();
  return state === 'valid' ? children : null;
};

const Rejected = ({ children }: ProtectorChildProps) => {
  const { state } = useProtectorContext();
  return state === 'invalid' ? children : null;
};

Protector.Allowed = Allowed;
Protector.Rejected = Rejected;

export default Protector;
